import HttpClient from "@/services/axios/instance";

import { catchAxiosError } from "@/services/axios/error";

import { IResponse } from "@/services/axios/entities";

import {
  IChangePasswordForm,
  ICreateUpdateUserForm,
  IUpdateUserProfileForm,
} from "@/models/User";
import { ICreateUpdateCustomerForm } from "@/models/Customer";

const AUTH = "Auth";

export class AccountAPI extends HttpClient {
  constructor(token?: string) {
    super(process.env.VUE_APP_AUTHENTICATION_SERVICE, token);
  }

  public login = async (data: any): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${AUTH}/login`, data)
      .catch(catchAxiosError);

    return response;
  };

  public createAccount = async (
    user: ICreateUpdateCustomerForm | ICreateUpdateUserForm
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(AUTH, user)
      .catch(catchAxiosError);

    return response;
  };

  public updateUserProfile = async (
    user: IUpdateUserProfileForm
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${AUTH}/me`, user)
      .catch(catchAxiosError);

    return response;
  };

  public fetchAccountProfile = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${AUTH}/me`)
      .catch(catchAxiosError);

    return response;
  };

  public changePassword = async (
    data: IChangePasswordForm
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${AUTH}/password`, data)
      .catch(catchAxiosError);

    return response;
  };
}
