import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true, name: "LocalStorage" })
export default class LocalStorage extends VuexModule {
  token = "";
  UI = {
    isToggleSidebar: false,
  };

  @Mutation
  saveToken(token: string): void {
    this.token = token;
  }

  @Mutation
  removeToken(): void {
    this.token = "";
  }

  @Action
  setToken(token: string): void {
    this.saveToken(token);
  }

  @Mutation
  toggleSidebar(): void {
    this.UI.isToggleSidebar = !this.UI.isToggleSidebar;
  }
}
